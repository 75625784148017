<template>
  <main class="main page-inside">
    <div class="main-wrapper">
      <header-wrapper class="section hero-section">
        <div class="wrapper">
          <div class="content">
            <div class="card-list">
              <template v-if="!$parent.checkoutSuccess">
                <div class="card-list__item order-details">
                  <div class="title small">Order details</div>
                  <div class="desc">Choose payment method:</div>
                  <div class="radio-list">
                    <div class="list">
                      <div
                        class="item"
                        v-if="
                          !$parent.fiatToCrypto ||
                          ($parent.fiatToCrypto && paymentMethod == 'card')
                        "
                      >
                        <label>
                          <input
                            v-if="$parent.fiatToCrypto"
                            type="radio"
                            v-model="paymentMethod"
                            name="paymentMethod"
                            value="card"
                          />
                          <input
                            v-if="!$parent.fiatToCrypto"
                            type="radio"
                            v-model="paymentMethod"
                            name="paymentMethod"
                            value="crypto"
                          />
                          <div class="text">
                            <svg
                              v-if="$parent.fiatToCrypto"
                              class="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24.667 17.841"
                            >
                              <g
                                id="Layer_1"
                                transform="translate(-2.13 -5.896)"
                              >
                                <path
                                  id="Контур_12558"
                                  data-name="Контур 12558"
                                  d="M6.467,23.737h16A4.338,4.338,0,0,0,26.8,19.4V10.229A4.338,4.338,0,0,0,22.464,5.9h-16a4.338,4.338,0,0,0-4.333,4.333v1.544c0,.008,0,.014,0,.021s0,.014,0,.021V19.4a4.338,4.338,0,0,0,4.333,4.333Zm16-1.766h-16A2.57,2.57,0,0,1,3.9,19.4V12.678H25.031V19.4A2.57,2.57,0,0,1,22.464,21.972Zm-16-14.31h16a2.57,2.57,0,0,1,2.567,2.567v.683H3.9v-.683A2.57,2.57,0,0,1,6.467,7.662Z"
                                  transform="translate(0 0)"
                                  fill="#7f8798"
                                />
                                <path
                                  id="Контур_12559"
                                  data-name="Контур 12559"
                                  d="M10.865,20.469H6.737a.883.883,0,1,0,0,1.766h4.128a.883.883,0,0,0,0-1.766Z"
                                  transform="translate(-0.436 -1.707)"
                                  fill="#7f8798"
                                />
                              </g>
                            </svg>
                            <div class="desc">
                              {{
                                $parent.fiatToCrypto
                                  ? "Visa/Mastercard"
                                  : "Crypto"
                              }}
                            </div>
                          </div>
                        </label>
                      </div>
                      <div
                        v-if="$parent.fiatToCrypto && paymentMethod == 'eps'"
                        class="item"
                      >
                        <label>
                          <input
                            type="radio"
                            v-model="paymentMethod"
                            name="paymentMethod"
                            value="eps"
                          />
                          <div class="text">
                            <img src="@/images/methods/eps.svg" class="img" />
                            <div class="desc">Eps</div>
                          </div>
                        </label>
                      </div>
                      <div
                        class="item"
                        v-if="
                          $parent.fiatToCrypto && paymentMethod == 'trustly'
                        "
                      >
                        <label>
                          <input
                            type="radio"
                            v-model="paymentMethod"
                            name="paymentMethod"
                            value="trustly"
                          />
                          <div class="text">
                            <img
                              src="@/images/methods/trustly.svg"
                              class="img"
                            />
                            <div class="desc">Trustly</div>
                          </div>
                        </label>
                      </div>
                      <div
                        class="item"
                        v-if="
                          $parent.fiatToCrypto && paymentMethod == 'giropay'
                        "
                      >
                        <label>
                          <input
                            type="radio"
                            v-model="paymentMethod"
                            name="paymentMethod"
                            value="giropay"
                          />
                          <div class="text">
                            <img
                              src="@/images/methods/giropay.svg"
                              class="img"
                            />
                            <div class="desc">Giropay</div>
                          </div>
                        </label>
                      </div>
                      <div
                        class="item"
                        v-if="$parent.fiatToCrypto && paymentMethod == 'blik'"
                      >
                        <label>
                          <input
                            type="radio"
                            v-model="paymentMethod"
                            name="paymentMethod"
                            value="blik"
                          />
                          <div class="text">
                            <img src="@/images/methods/blik.svg" class="img" />
                            <div class="desc">Blik</div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="input-container">
                    <div class="desc">Wallet:</div>
                    <input type="text" v-model="wallet" />
                    <div class="clue">Please provide your wallet</div>
                  </div>
                  <div class="total">
                    <div class="desc">Total amount to pay:</div>
                    <div class="total-wrapper">
                      <div class="item">
                        <div class="text">
                          <div class="desc">Date of order</div>
                          <div class="desc">{{ currentDate }}</div>
                        </div>
                        <div
                          class="title center"
                          :class="{ crypto: !$parent.fiatToCrypto }"
                        >
                          {{ leftAmount }}
                        </div>
                      </div>
                      <div class="item">
                        <div class="text">
                          <div class="desc">Exchange rate</div>
                          <div class="desc">
                            {{ $parent.takeCurrency.crypto_currency }} =
                            {{ $parent.takeCurrency.price }}
                            {{ $parent.giveCurrency.symbol }}
                          </div>
                        </div>
                        <div
                          class="title center"
                          :class="{ crypto: $parent.fiatToCrypto }"
                        >
                          {{ rightAmount }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="total-bottom">
                    <router-link to="buy-crypto" class="button gray"
                      >Cancel order</router-link
                    >
                    <button class="button" @click="submit">Continue</button>
                  </div>
                </div>
                <div class="card-list__item-container">
                  <div class="card-list__item your-card">
                    <div class="title small">Your Card</div>
                    <div class="row">
                      <div class="title center">{{ leftAmount }}</div>
                      <router-link to="buy-crypto" class="button gray"
                        >Change amount</router-link
                      >
                    </div>
                    <div class="desc">{{ rightAmount }}</div>
                    <div class="table">
                      <div class="row">
                        <div class="desc">Exchange rate</div>
                        <div class="desc">
                          1 {{ $parent.takeCurrency.crypto_currency }} =
                          {{ $parent.takeCurrency.price }}
                          {{ $parent.giveCurrency.symbol }}
                        </div>
                      </div>
                      <div class="row">
                        <div class="desc">
                          {{ $parent.fiatToCrypto ? "Coins" : "Money" }} to
                          receive
                        </div>
                        <div class="desc">{{ rightAmount }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="timer">
                    <div class="title center white">
                      {{ hours }}:{{ minutes }}:{{ seconds }}
                    </div>
                    <div class="desc">The price is fixes for 1 hour</div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="card-list__item order-success">
                  <div class="order-submit-success">
                    <div class="order-submit-success__title">
                      Order successfully submitted
                    </div>
                    <div class="order-submit-success__message"></div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </header-wrapper>
    </div>
  </main>
</template>
<script>
import fiatSection from "@/components/FiatSection.vue";
import HeaderWrapper from "@/components/HeaderWrapper.vue";

export default {
  name: "OrderDetails",
  props: {
    feePercentage: Number,
  },
  components: {
    HeaderWrapper,
  },
  data: function () {
    return {
      paymentMethod: "card",
      wallet: "",
      hours: 1,
      minutes: 0,
      seconds: 0,
      timer: null,
      currentDate: "",
      submitSuccess: false,
    };
  },
  mounted: function () {
    if (this.$parent.paymentMethod) {
      this.paymentMethod = this.$parent.paymentMethod;
    }

    this.$parent.changeCheckoutSuccess(false);
    this.startTimer();
    this.getCurrentDate();
  },
  methods: {
    getCurrentDate() {
      const today = new Date();
      const formattedDate = today.toISOString().split("T")[0];

      this.currentDate = formattedDate;
    },
    startTimer() {
      this.timer = setInterval(() => {
        if (this.hours === 0 && this.minutes === 0 && this.seconds === 0) {
          clearInterval(this.timer);
        } else {
          if (this.seconds > 0) {
            this.seconds--;
          } else {
            if (this.minutes > 0) {
              this.minutes--;
              this.seconds = 59;
            } else {
              this.hours--;
              this.minutes = 59;
              this.seconds = 59;
            }
          }
        }
      }, 1000);
    },
    submit() {
      this.$parent.openPersonalInfoModal();
      this.$emit("setWallet", this.wallet);
      this.$emit("setPaymentMethod", this.paymentMethod);
    },
  },
  computed: {
    calculatedFees() {
      if (
        !this.$parent.takeAmount ||
        !this.$parent.giveCurrency ||
        !this.$parent.takeCurrency
      ) {
        return null;
      }

      const isFiatToCrypto = this.$parent.fiatToCrypto;
      // console.log(isFiatToCrypto);

      const commissionRate = this.feePercentage ?? 0; // fee

      let feeInGiveCurrency, totalInGiveCurrency;

      // Fee and Total calculation
      if (isFiatToCrypto) {
        // Fiat-to-Crypto
        feeInGiveCurrency = (
          parseFloat(this.$parent.giveAmount) * parseFloat(commissionRate)
        ).toFixed(2); // Fee in EUR
        totalInGiveCurrency = (
          parseFloat(this.$parent.giveAmount) + parseFloat(feeInGiveCurrency)
        ).toFixed(2); // Total in EUR
      } else {
        // Crypto-to-Fiat
        feeInGiveCurrency = (this.$parent.takeAmount * commissionRate).toFixed(
          10
        ); // Fee in BTC
        totalInGiveCurrency = (
          this.$parent.takeAmount + parseFloat(feeInGiveCurrency)
        ).toFixed(10); // Total in BTC
      }

      // console.log(
      //   feeInGiveCurrency,
      //   totalInGiveCurrency,
      //   this.$parent.giveAmount,
      //   this.$parent.giveCurrency,
      //   this.$parent.takeCurrency,
      //   this.$parent.takeAmount
      // );

      // Display appropriate symbols
      const feeSymbol = isFiatToCrypto
        ? this.$parent.giveCurrency.code
        : this.$parent.takeCurrency.crypto_currency;
      const totalSymbol = feeSymbol;

      return {
        commissionFee: `${feeInGiveCurrency} ${feeSymbol}`,
        total: `${totalInGiveCurrency} ${totalSymbol}`,
      };
    },
    fiatSection() {
      return fiatSection;
    },
    leftAmount() {
      if (this.calculatedFees?.total) {
        return this.calculatedFees.total;
      }
      // console.log(this.calculatedFees);
      const parent = this.$parent;
      return parent.fiatToCrypto
        ? `${parent.giveCurrency.symbol} ${parseFloat(
            parent.giveAmount
          ).toFixed(2)}`
        : `${parent.takeAmount} ${parent.takeCurrency.crypto_currency}`;
    },
    rightAmount() {
      const parent = this.$parent;
      return parent.fiatToCrypto
        ? `${parent.takeAmount} ${parent.takeCurrency.crypto_currency}`
        : `${parent.giveCurrency.symbol} ${parseFloat(
            parent.giveAmount
          ).toFixed(2)}`;
    },
  },
};
</script>
