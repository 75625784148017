<template>
  <div class="fiat-section">
    <div class="input-container">
      <input
        type="number"
        :value="giveAmount"
        @input="
          $emit('update:give-amount', Number($event.target.value));
          changeTakeAmount(Number($event.target.value));
        "
      />
      <div v-if="giveCurrency.title == 'Euro'" class="desc">EUR</div>
      <div v-else-if="giveCurrency.title == 'US dollar'" class="desc">USD</div>
      <div v-else class="desc">{{ giveCurrency.title }}</div>
    </div>

    <div class="fee-list" v-if="calculatedFees && fiatToCrypto == false">
      <div class="list">
        <div class="desc title">Fees:</div>
        <div class="item item-fee">
          <div class="text">
            <div class="key">Our fee:</div>
            <div class="value">
              {{
                calculatedFees.commissionFee
                  ? calculatedFees.commissionFee
                  : "Processing..."
              }}
            </div>
          </div>
        </div>
        <div class="item item-total">
          <div class="text">
            <div class="key">Total:</div>
            <div class="value">
              {{
                calculatedFees.total ? calculatedFees.total : "Processing..."
              }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="radio-buttons">
      <div class="desc">Choose a currency:</div>
      <div class="list" v-if="currencies">
        <div class="item" v-for="item in currencies" :key="item.title">
          <label>
            <input
              type="radio"
              :checked="giveCurrency.title === item.title"
              :value="item.title"
              @change="chooseCurrency(item)"
            />
            <div v-if="item.title == 'Euro'" class="desc">EUR</div>
            <div v-else-if="item.title == 'US dollar'" class="desc">USD</div>
            <div v-else class="desc">{{ item.title }}</div>
          </label>
        </div>
      </div>
    </div>
    <div v-if="!fiatToCrypto" class="radio-list payment-method-list">
      <div class="desc">Payment method:</div>
      <div class="list">
        <div class="item">
          <label>
            <input
              type="radio"
              v-model="paymentMethod"
              @change="$emit('updatePaymentMethod', paymentMethod)"
              name="paymentMethod"
              value="crypto"
            />
            <div class="text">
              <div class="desc">Crypto</div>
            </div>
          </label>
        </div>
      </div>
    </div>
    <div v-if="fiatToCrypto" class="radio-list payment-method-list">
      <div class="desc">Payment method:</div>
      <div class="list" v-if="paymentMethods && paymentMethods.length">
        <div class="item" v-for="(item, i) in paymentMethods" :key="i">
          <label>
            <input
              type="radio"
              v-model="paymentMethod"
              @change="$emit('updatePaymentMethod', paymentMethod)"
              name="paymentMethod"
              :value="item.code"
            />
            <div class="text">
              <img :src="appDomain + item.image" class="img" />
              <div class="desc">{{ item.title }}</div>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FiatSection",
  props: {
    appDomain: {
      type: String,
    },
    paymentMethods: {
      type: Array,
    },
    giveCurrency: {
      type: Object,
    },
    giveAmount: {
      type: Number,
    },
    defaultTakeCurrency: {
      type: Function,
    },
    currencies: {
      type: Object,
    },
    changeTakeAmount: {
      type: Function,
    },
    toggleFiatToCrypto: {
      type: Function,
    },
    fiatToCrypto: {
      type: Boolean,
    },
    calculatedFees: {
      type: Object,
    },
  },
  data: function () {
    return {
      paymentMethod: "card",
    };
  },
  methods: {
    chooseCurrency(event) {
      this.$emit("update:give-currency", event);
      this.defaultTakeCurrency();
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.fiat-section .fee-list {
  margin-top: 16px;

  .key {
    font-size: 16px;
    font-weight: 500;
    line-height: 23.17px;
    text-align: left;
    color: #c2c9d5;
  }

  .value {
    font-size: 16px;
    font-weight: 500;
    line-height: 23.17px;
    color: #fff;
  }

  .text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  .item-fee {
    margin-bottom: 32px;
  }

  .desc.title {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 700;
    line-height: 23.17px;
  }
}
</style>
