<template>
  <div class="cookie-modal" v-if="showCookieModal">
    <div class="cookie-content">
      <p class="desc">
        We use cookies on our website to enhance your experience. Before you
        proceed with using our Website, please get acquainted with the way we
        handle a User’s data and the use of Cookies in our
        <a @click="goToPage('privacy')" class="desc link">{{ hasPrivacy }}</a
        >.
      </p>
      <button class="button" @click="acceptCookies">Accept</button>
      <button @click="declineCookies" class="button gray decline-button">
        Decline
      </button>
    </div>
  </div>
</template>

<script>
import VueCookies from "vue-js-cookie";

export default {
  data() {
    return {
      showCookieModal: VueCookies.get("cookiesAccepted") !== "true",
    };
  },
  computed: {
    hasPrivacy() {
      if (this.$parent.textPageList && this.$parent.textPageList.length) {
        let result = this.$parent.textPageList.filter(
          (item) => item.is_privacy
        );
        return result && result.length ? result[0].title : "Privacy Policy";
      }
      return "Privacy Policy";
    },
  },
  methods: {
    goToPage(type) {
      if (type === "privacy" && this.hasPrivacy) {
        this.$parent.goToPrivacy();
      } else {
        if (this.$route.path !== "/" && this.$route.path !== "/home") {
          this.$router.push("/");
        }
      }
    },
    acceptCookies() {
      this.showCookieModal = false;
      VueCookies.set("cookiesAccepted", "true", 365); // Set a cookie that lasts for a year
    },
    declineCookies() {
      this.showCookieModal = false;
      VueCookies.set("cookiesAccepted", "false", 365);
    },
  },
  mounted() {
  },
};
</script>
<style scoped>
.cookie-modal {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  z-index: 2;
}
.button {
  margin: 16px 16px 0;
  width: 100px;
  display: inline-block;
}
.cookie-content {
  font-size: 16px;
  margin-bottom: 10px;
}

.accept-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.accept-button:hover {
  background-color: #0056b3;
}
</style>
