<template>
  <div class="crypto-section">
    <div class="input-container">
      <input
        type="number"
        :value="takeAmount"
        @input="
          $emit('update:take-amount', Number($event.target.value));
          changeGiveAmount(Number($event.target.value));
        "
      />
      <div class="desc">{{ takeCurrency.crypto_currency }}</div>
    </div>

    <div class="fee-list" v-if="calculatedFees && fiatToCrypto == true">
      <div class="list">
        <div class="desc title">Fees:</div>
        <div class="item item-fee">
          <div class="text">
            <div class="key">Our fee:</div>
            <div class="value">
              {{
                calculatedFees.commissionFee
                  ? calculatedFees.commissionFee
                  : "Processing..."
              }}
            </div>
          </div>
        </div>
        <div class="item item-total">
          <div class="text">
            <div class="key">Total:</div>
            <div class="value">
              {{
                calculatedFees.total ? calculatedFees.total : "Processing..."
              }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="radio-list cryptocurrency-list">
      <div class="list" v-if="rates && giveCurrency.title == 'Euro'">
        <div class="desc title">Assets:</div>
        <div class="item" v-for="item in filteredRatesEur" :key="item.id">
          <label>
            <input
              type="radio"
              :checked="takeCurrency.crypto_currency === item.crypto_currency"
              @change="$emit('update:take-currency', item)"
              :value="item.crypto_currency"
            />
            <div class="text">
              <div class="desc">{{ item.crypto_currency }}</div>
              <div class="desc">{{ item.price }} : 1</div>
            </div>
          </label>
        </div>
      </div>
      <div class="list" v-if="rates && giveCurrency.title == 'US dollar'">
        <div class="item" v-for="item in filteredRatesUsd" :key="item.id">
          <label>
            <input
              type="radio"
              :checked="takeCurrency.crypto_currency === item.crypto_currency"
              @change="$emit('update:take-currency', item)"
              :value="item.crypto_currency"
            />
            <div class="text">
              <div class="desc">{{ item.crypto_currency }}</div>
              <div class="desc">{{ item.price }} : 1</div>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CryptoSection",
  props: {
    fiatToCrypto: {
      type: Boolean,
    },
    giveCurrency: {
      type: Object,
    },
    rates: {
      type: Array,
    },
    filteredRatesEur: {
      type: Array,
    },
    filteredRatesUsd: {
      type: Array,
    },
    takeCurrency: {
      type: Object,
    },
    takeAmount: {
      type: Number,
    },
    changeGiveAmount: {
      type: Function,
    },
    toggleFiatToCrypto: {
      type: Function,
    },
    paymentMethod: {
      type: String,
    },
    calculatedFees: {
      type: Object,
    },
  },
  data: function () {
    return {};
  },
  watch: {},
  methods: {},
  computed: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.crypto-section .fee-list {
  margin-top: 16px;

  .key {
    font-size: 16px;
    font-weight: 500;
    line-height: 23.17px;
    text-align: left;
    color: #c2c9d5;
  }

  .value {
    font-size: 16px;
    font-weight: 500;
    line-height: 23.17px;
    color: #fff;
  }

  .text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  .item-fee {
    margin-bottom: 32px;
  }

  .desc.title {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 700;
    line-height: 23.17px;
  }
}
</style>
