<template>
  <div class="modal register-modal">
    <div class="overlay" @click="$parent.closePersonalInfoModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closePersonalInfoModal" src="./../images/close.svg" />
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="form-fields-wrapper">
                <div class="title">Checkout</div>
                <label>
                  <!--                  <div class="desc">*First name</div>-->
                  <input type="text" placeholder="First name *" v-model="name" />
                </label>
                <label>
                  <!--                  <div class="desc">*Last Name</div>-->
                  <input type="text" placeholder="Last Name *" v-model="surname" />
                </label>
                <label v-if="!profileBirthdate">
                  <!--                <div class="desc">*Birthdate</div>-->
                  <input ref="dob" @focus="showDatePicker" @blur="hideDatePicker" type="text" placeholder="Birthdate"
                    v-model="birthdate" required />
                </label>
                <label>
                  <!--                  <div class="desc">*Email</div>-->
                  <input type="email" placeholder="Email *" v-model="email" />
                </label>
                <label>
                  <VuePhoneNumberInput v-model="phone" :only-countries="countryOptionsIso"
                    :default-country-code="defaultCountryIso" @update="phoneDataUpdate($event)" />
                </label>
                <label>
                  <!--                  <div class="desc">*Address</div>-->
                  <input type="text" placeholder="Address *" v-model="address" />
                </label>
                <label>
                  <!--                  <div class="desc">*City</div>-->
                  <input type="text" placeholder="City *" v-model="city" />
                </label>
                <label>
                  <!--                  <div class="desc">*Post code</div>-->
                  <input type="email" placeholder="Post code *" v-model="postCode" />
                </label>
                <label>
                  <!--                  <div class="desc">*Country</div>-->
                  <!-- <Select2 class="select2 single" v-model="country" 
                  :options="countryOptions" 
                  @select="selectCountry($event)"
                  placeholder="Country *"
                  /> -->
                  <!-- <select v-model="country">
                    <option disabled selected value="">Country</option>
                    <option v-for="(item, i) in countryOptions" :value="item" :key="i">{{ item.title }}</option>
                  </select> -->
                  <Dropdown :options="countryOptions" value-key="id" label-key="title" placeholder="Country"
                    :default-value="defaultCountry" container-class="country-dropdown"
                    :container-style="{ width: '100%' }" :clearable="true" @select="onCountrySelect"
                    @clear="onCountryClear" :not-selected-option-style="{ color: 'rgb(117, 117, 117)' }" />
                </label>
                <div class="link-container">
                  <div class="chekbox-container">
                    <label class="chekbox-label">
                      <div class="chekbox-label-wrapper">
                        <input type="checkbox" name="terms" v-model="terms" />
                        <div class="checkbox"></div>
                        <span class="desc">I agree with </span>
                        <a @click="$parent.goToPrivacy()" class="desc link">
                          {{ getPrivacyTitle }}
                        </a>
                        <span class="desc"> and </span>
                        <a @click="$parent.goToTerms()" class="desc link">
                          {{ getTermsTitle }}
                        </a>
                      </div>
                    </label>
                  </div>
                </div>
                <button :class="['button', { 'disabled': !requiredRegisterFieldsAreFilled }]" @click="submit">
                  <span>Submit</span>
                </button>
                <div v-if="$parent.error" class="desc red">{{ $parent.error }}</div>
                <div class="legal-info">
                  <div class="info">{{ $parent.legalInfo }}</div>
                  <div class="billing-descriptor">
                    {{ $parent.billingDescriptor }}
                  </div>
                  <!-- <div class="vsmc">
                    <img src="@/images/mc.svg" class="img" />
                    <img src="@/images/visa.svg" class="img" />
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PersonalInfoModal',
  props: [],
  components: {
  },
  data: function () {
    return {
      countryOptions: [],
      countryOptionsId: [],
      email: '',
      name: '',
      surname: '',
      profileBirthdate: null,
      birthdate: null,
      phone: '',
      country: '',
      countryId: '',
      city: '',
      address: '',
      postCode: '',
      terms: false,
      countryOptionsIso: [],
      phoneFull: '',
      phoneCountryCode: ''

    }
  },
  mounted() {
    this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
        this.email = res.data.email;
        this.name = res.data.name;
        this.surname = res.data.surname;
        this.profileBirthdate = res.data.birth_date;
        this.birthdate = res.data.birth_date;
        this.phone = res.data.phone;

        this.address = res.data.address;
        this.postCode = res.data.post_code;
        this.city = res.data.city;
        this.country = res.data.country;
        this.phoneCountryCode = res.data.phone_country_code;
      })
      .catch(() => {

      })
    this.getCountries();
  },
  computed: {
    getPrivacyTitle() {
      return this.$parent.textPageList.filter(item => item.is_privacy)[0]?.title;
    },
    getTermsTitle() {
      return this.$parent.textPageList.filter(item => item.is_terms)[0]?.title;
    },
    defaultCountry() {
      if (this.country && this.countryOptions.length > 0) {
        return this.countryOptions.find(option => option.id === this.country?.id) || this.countryOptions[0];
      } else {
        return null;
      }
    },
    defaultCountryIso() {
      return this.phoneCountryCode || this.countryOptionsIso[0];
    },
    requiredRegisterFieldsAreFilled() {
      let fieldsAreFilled;

      if (
        this.name && this.surname && this.phone &&
        this.email && this.terms && this.city && this.country &&
        this.postCode && this.address && (this.$parent.fiatToCrypto || this.birthdate)
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  methods: {
    onCountrySelect(country) {
      this.country = country;
    },
    onCountryClear() {
      this.country = '';
    },
    // selectCountry(event) {
    //   this.country = event.text;
    //   for (let i = 0; i < this.countryOptions.length; i++) {
    //     if (this.countryOptions[i] == event.text) {
    //       this.countryId = this.countryOptionsId[i];
    //     }
    //   }
    // },
    phoneDataUpdate(e) {
      this.phoneFull = e.formatInternational || e.phoneNumber;
    },
    getCountries() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
        .then((res) => {
          this.countryOptions = res.data.payload;
          this.countryOptionsIso = res.data.payload.map(obj => obj.iso);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        })
    },
    submit() {
      this.$emit('setPersonalInfo', this.email, this.name, this.surname, this.phoneFull, this.postCode, this.country.id, this.city, this.address, this.birthdate)
      this.$emit('orderSubmit');
    },
    showDatePicker() {
      this.$refs.dob.type = 'date';
      this.$refs.dob.focus();
    },
    hideDatePicker() {
      if (!this.birthdate) {
        this.$refs.dob.type = 'text';
        this.birthdate = null;
      }
    }
  }
}
</script>